
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'Divider',
})
export default class Divider extends Vue {
  @Prop({ default: false }) marginX: boolean;
  @Prop({ default: false }) noMargin: boolean;
}
